import axios from "axios";
import { useQuery } from "react-query";

import { FetchAppsResponse } from "@/shared/types/Apps";

import { OnPressAppFn, OnViewAllFn } from "@/shared/NineSquareButton/types";

const fetchApps = async (): Promise<FetchAppsResponse> => {
  const { data } = await axios.get<{ records: FetchAppsResponse[] }>(
    "/api/v1/users"
  );

  return data.records[0];
};
export const onPressApp: OnPressAppFn = async (app) => {
  const { data } = await axios.get<{ redirect_uri: string }>(
    `/api/v1/applications/${app.name}/redirect-link?path=/`
  );

  return data.redirect_uri;
};
export const onPressViewAll: OnViewAllFn = async () => {
  return "/";
};

export const useApps = () =>
  useQuery({
    queryKey: ["apps"],
    queryFn: fetchApps,
    staleTime: 10000,
  });
