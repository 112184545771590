import React, { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import {
  Box,
  Chip,
  HStack,
  Skeleton,
  Stack,
  Typography,
  useTheme,
  VStack,
} from "@smartrent/ui";

import { UserApplication } from "@/shared/types";

import { AuthenticatedLayout } from "@/bundles/authenticated/layouts/AuthenticatedLayout";
import { useApps } from "@/bundles/authenticated/api/apps";

export const ApplicationListPage = () => {
  const { data: apps, isLoading } = useApps();
  const appCards = useMemo(() => {
    return apps?.applications.map((app) => (
      <ApplicationCard key={app.name} app={app} />
    ));
  }, [apps]);

  return (
    <AuthenticatedLayout>
      <View style={styles.container}>
        <Typography type="title2">SmartRent - App Manager</Typography>
        <Typography type="caption">
          Welcome to App Manager, where you can link all your SmartRent accounts
          together.
        </Typography>
        <Typography type="caption">
          Once linked, you can jump between products easily without having to
          constantly log back in.
        </Typography>

        <Stack
          wrap={true}
          direction="row"
          spacing={24}
          align="stretch"
          style={{ marginTop: 24 }}
        >
          {isLoading ? loadingApplicationCards() : appCards}
        </Stack>
      </View>
    </AuthenticatedLayout>
  );
};

interface ApplicationCardProps {
  app: UserApplication;
}
const ApplicationCard: React.FC<ApplicationCardProps> = ({ app }) => {
  const { colors } = useTheme();
  return (
    <Box style={[styles.applicationCard, { borderColor: colors.border }]}>
      <VStack align="center" spacing={16}>
        <Typography type="title4">{app.meta.label}</Typography>
        <img
          src={`/images/${app.name}.svg`}
          style={{ width: 100, height: 100 }}
          alt={app.meta.label}
        />
        {app.meta.tags.length ? (
          <Typography type="bodySmallSemibold" color="labelText">
            FEATURES
          </Typography>
        ) : null}
        <HStack spacing={8}>
          {app.meta.tags.map((tag) => (
            <Chip
              key={`application-card-${app.name}-${tag}`}
              color="grayscale"
              variation="muted"
              corners="badge"
              disabled={true}
            >
              {tag.toUpperCase()}
            </Chip>
          ))}
        </HStack>
      </VStack>
    </Box>
  );
};

// Because of the way `Stack` works, we need to use a function instead of a component to just return the children.
// Otherwise, the `Stack` will try to render <LoadingApplicationCards /> as a single child.
const loadingApplicationCards = () => {
  return (
    <>
      <Skeleton height={214} width={364} style={styles.skeleton} />
      <Skeleton height={214} width={364} style={styles.skeleton} />
      <Skeleton height={214} width={364} style={styles.skeleton} />
    </>
  );
};

const styles = StyleSheet.create({
  container: { maxWidth: "80%", marginHorizontal: "auto" },
  applicationCard: {
    minWidth: 364,
    boxShadow: "none",
    borderWidth: 1,
    marginBottom: 16,
  },
  skeleton: {
    borderRadius: 4,
  },
});
