import React from "react";
import { View, StyleSheet } from "react-native";
import { useTheme } from "@smartrent/ui";

import { TopNav } from "@/shared/TopNav";

export const AuthenticatedLayout: React.FC<React.PropsWithChildren<object>> = ({
  children,
}) => {
  const { colors } = useTheme();
  return (
    <View>
      <TopNav />

      <View
        style={[
          styles.content,
          {
            backgroundColor: colors.gray075,
          },
        ]}
      >
        {children}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  content: {
    padding: 16,
    height: "calc(100vh - 72px)",
  },
});
