import { Route, Switch } from "react-router-dom";

import { ApplicationListPage } from "../pages/ApplicationListPage";

export function AuthenticatedRouter() {
  return (
    <Switch>
      <Route exact path="/">
        <ApplicationListPage />
      </Route>
    </Switch>
  );
}
