import { useTheme, VStack } from "@smartrent/ui";

interface ContextMenuSectionProps {
  children: React.ReactNode;
}

export const ContextMenuSection: React.FC<ContextMenuSectionProps> = ({
  children,
}) => {
  const { spacing } = useTheme();
  return (
    <VStack
      style={{
        paddingVertical: spacing.xxs,
      }}
      spacing={2}
    >
      {children}
    </VStack>
  );
};
