import { SmartRentLogo } from "@smartrent/icons";
import React, { Suspense } from "react";

type IconImportType = typeof import("@smartrent/icons");

interface IconProps {
  name: string;
  size?: number;
  color?: string;
}

// Seeing a flash of the SmartRentLogo when the icon is lazy loaded
// This is just a placeholder until we get actual images from design
export const Icon = ({ name, ...iconProps }: IconProps) => {
  if (!name) {
    return null;
  }
  // lazy import icons
  const IconComponent = React.lazy(() =>
    import("@smartrent/icons").then((module: IconImportType) => ({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- difficult to find the correct type
      default: module[name as keyof IconImportType] as any,
    }))
  );

  return (
    <Suspense fallback={<SmartRentLogo {...iconProps} />}>
      <IconComponent {...iconProps} />
    </Suspense>
  );
};
