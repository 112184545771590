// This component will be shared with other applications. It's designed to be independent of other code in this repo.
// This needs to be placed in a shared package.

import { Pressable, View } from "react-native";
import { useCallback, useMemo } from "react";

import { useMenu } from "@smartrent/hooks";
import { Grid3X3Solid } from "@smartrent/icons";
import { Button, Typography, useTheme } from "@smartrent/ui";

import {
  ContextMenu,
  ContextMenuItem,
  ContextMenuSection,
  ContextMenuSectionHeader,
} from "@/shared/ContextMenu";
import { Icon } from "@/shared/Icon";
import { useApps } from "@/bundles/authenticated/api/apps";

import { NavMenuProps, UserApplication } from "./types";
import { useNavMenu } from "./hooks";

interface AppsDOM {
  apps: UserApplication[];
  handlePressApp: (app: UserApplication) => void;
  urlLoading: string | null;
}

export const NineSquareButton: React.FC<NavMenuProps> = ({
  onPressApp,
  onPressViewAll,
  viewAllInNewTab = true,
}) => {
  const { colors } = useTheme();
  const { buttonProps, menuProps } = useMenu();
  const { urlLoading, handlePressApp, handlePressViewApp } = useNavMenu({
    onPressApp,
    onPressViewAll,
    viewAllInNewTab,
  });
  const { data: apps, isLoading, isError } = useApps();

  const renderApps = useCallback(() => {
    if (isLoading) {
      return null;
    }

    if (!apps || apps.applications.length <= 0) {
      return (
        <View>
          <Typography type="body">No apps found</Typography>
        </View>
      );
    }

    if (isError) {
      return (
        <View>
          <Typography type="body">Error fetching apps</Typography>
        </View>
      );
    }

    return (
      <>
        <ContextMenuSectionHeader title="My Apps" />
        <ContextMenuSection>
          <LinkedApps
            apps={apps.applications}
            handlePressApp={handlePressApp}
            urlLoading={urlLoading}
          />
        </ContextMenuSection>
        <ContextMenuSectionHeader title="Discover More Apps" />
        <ContextMenuSection>
          <UnlinkedApps
            apps={apps.applications}
            handlePressApp={handlePressApp}
            urlLoading={urlLoading}
          />
        </ContextMenuSection>
      </>
    );
  }, [apps, isLoading, isError, handlePressApp, urlLoading]);

  return (
    <View>
      <Pressable {...buttonProps}>
        <Grid3X3Solid color={colors.gray500} />
      </Pressable>
      <ContextMenu {...menuProps} isLoading={isLoading}>
        {renderApps()}
        <Button variation="plain" onPress={handlePressViewApp}>
          VIEW ALL APPS
        </Button>
      </ContextMenu>
    </View>
  );
};

function LinkedApps({ apps, urlLoading, handlePressApp }: AppsDOM) {
  const { colors } = useTheme();
  const linkedAppsDOM = useMemo(() => {
    return apps
      .filter((app) => Boolean(app.user_id))
      .map((app) => (
        <ContextMenuItem
          key={app.name}
          icon={<Icon name={app.meta.icon_name} color={colors.primary} />}
          title={app.meta.label}
          subtitle={app.meta.subtitle}
          onPress={() => handlePressApp(app)}
          isLoading={urlLoading === app.name}
        />
      ));
  }, [apps, colors, urlLoading, handlePressApp]);
  return <>{linkedAppsDOM}</>;
}

function UnlinkedApps({ apps, urlLoading, handlePressApp }: AppsDOM) {
  const { colors } = useTheme();
  const unlinkedAppsDOM = useMemo(() => {
    return apps
      .filter((app) => !app.user_id)
      .map((app) => (
        <ContextMenuItem
          key={app.name}
          icon={<Icon name={app.meta.icon_name} color={colors.primary} />}
          title={app.meta.label}
          subtitle={app.meta.subtitle}
          onPress={() => handlePressApp(app)}
          isLoading={urlLoading === app.name}
        />
      ));
  }, [apps, colors, urlLoading, handlePressApp]);

  return <>{unlinkedAppsDOM}</>;
}
