import { View, StyleSheet } from "react-native";
import { useTheme } from "@smartrent/ui";
import { Link } from "@smartrent/ui";

import { NineSquareButton } from "@/shared/NineSquareButton/NineSquareButton";
import { onPressApp, onPressViewAll } from "@/bundles/authenticated/api/apps";

export const TopNav = () => {
  const { colors } = useTheme();

  return (
    <View
      style={[
        styles.topNav,
        {
          borderBottomColor: colors.border,
          backgroundColor: colors.gray800,
        },
      ]}
    >
      <NineSquareButton
        onPressApp={onPressApp}
        onPressViewAll={onPressViewAll}
        viewAllInNewTab={true}
      />
      <Link to="/" style={{ lineHeight: 12 }}>
        <img
          src="https://control-smartrent-prod.imgix.net/logo.svg"
          style={{ width: "10rem", height: "3.5625rem" }}
          alt="SmartRent Logo"
        />
      </Link>
    </View>
  );
};

const styles = StyleSheet.create({
  topNav: {
    flexDirection: "row",
    alignItems: "center",
    height: "3.5625rem",
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    columnGap: 16,
  },
});
