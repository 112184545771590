import { useCallback, useState } from "react";

import { NavMenuProps } from "./types";

export const useNavMenu = ({
  onPressApp,
  onPressViewAll,
  viewAllInNewTab,
}: NavMenuProps) => {
  const [urlLoading, setUrlLoading] = useState<string | null>(null);
  const [urlError, setUrlError] = useState<string | undefined>(undefined);

  const handlePressApp = useCallback(
    async (app) => {
      try {
        setUrlLoading(app.name);
        const redirectUrl = await onPressApp(app);
        window.open(redirectUrl, "_blank");
      } catch (e) {
        setUrlError(
          `Error fetching redirect url for application: ${app.label}`
        );
      } finally {
        setUrlLoading(null);
      }
    },
    [onPressApp]
  );

  const handlePressViewApp = useCallback(async () => {
    try {
      setUrlLoading("view-all");
      const redirectUrl = await onPressViewAll();
      if (viewAllInNewTab) {
        window.open(redirectUrl, "_blank");
      } else {
        window.open(redirectUrl, "_self");
      }
    } catch (e) {
      setUrlError(`Error fetching redirect url to view all applications`);
    } finally {
      setUrlLoading(null);
    }
  }, [onPressViewAll, viewAllInNewTab]);

  return {
    urlLoading,
    urlError,
    handlePressApp,
    handlePressViewApp,
  };
};
